@import 'mdb-react-ui-kit/dist/scss/bootstrap/bootstrap';
@import "../../assets/styles/variables";

.under-construction-image {
  width: 50%;
}

.photo-copyright {

  font-size: .6rem;

  a {

    text-decoration: none;
    color: $color-primary;

    &:hover {

      color: $color-secondary;

    }

  }

}

@include media-breakpoint-down(sm) {
  .under-construction-image {
    width: 100%;
  }
}