@import '../../assets/styles/variables';

.btn-app {

  &.btn-app-primary {

    background-color: $color-primary;
    border: darken($color-primary, 20);
    text-transform: none;

    &:hover {

      background-color: $color-secondary;
      border: darken($color-secondary, 20);

    }

  }

}