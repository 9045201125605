@import '../../assets/styles/variables';

.contact-page {

  .address-col {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .address-elem {

      display: flex;
      gap: 1.5rem;
      align-items: center;

      .icon {

        height: fit-content;
        min-width: 3rem;
        color: $color-primary;

        &:before {

          font-size: 2.5rem;

        }

      }

      p {

        font-size: 1.2rem;
        margin: 0;
        font-weight: 400;

        a {

          text-decoration: none;
          color: $color-gray;

          &:hover {

            color: $color-secondary;

          }

        }

      }

    }

  }

}