@import '../../assets/styles/variables';
@import 'mdb-react-ui-kit/dist/scss/bootstrap/bootstrap';

.offer-element {
  border: .2rem solid $color-secondary-half-opacity;
  background-color: $color-white;
  border-radius: .5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .offer-element-top {

    margin-bottom: 2rem;

  }


  .offer-element-icon {
    font-size: 4.5rem;
    color: $color-secondary;
    margin-bottom: 2rem;
    text-align: center;
    margin-inline: auto;
    display: block;
  }

  .offer-element-title {
    color: $color-gray;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.25rem;
    word-break: keep-all;
    margin: 0;
  }

  .offer-element-content {
    color: $color-gray;
    text-align: center;
    font-weight: 400;
    font-size: 1.1rem;
    margin: 0;
  }
}