@import '../../assets/styles/variables';
@import 'mdb-react-ui-kit/dist/scss/bootstrap/bootstrap';

.navbar-light {
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: $color-secondary;
        font-weight: 500;
        font-size: 1.5rem;

        &.active {
          color: $color-primary;

          &:hover {
            color: $color-secondary;
          }
        }

        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}

.toggler-icon {
  color: $color-primary;
}

@include media-breakpoint-down(md) {
  .navbar-light {
    .navbar-toggler {
      &:focus {
        box-shadow: none;
      }
    }

    .navbar-nav {
      padding: 0 0 2em;

      .nav-item {
        border-bottom: 1px solid $color-light-gray;
        margin: 0 1em;

        .nav-link {
          color: $color-dark-gray;
          font-size: 1rem;

          &.active {
            color: $color-secondary;

            &:hover {
              color: $color-primary;
            }
          }

          &:hover {
            color: $color-secondary;
          }
        }
      }
    }
  }
}
