@import '../../assets/styles/variables';

.app-heading {

  color: $color-light-gray-full-opacity;

  &.h1 {
    color: $color-dark-gray;
    font-weight: 800;
    font-size: 2rem;
  }

  &.h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  &.h3 {
    font-size: 1.5rem;
    font-weight: 400;
  }

  &.primary-background {
    color: $color-white!important;
  }

  &.heading-special {
    color: $color-primary;
    margin-block: 2rem;
    position: relative;
    border-bottom: .3rem solid $color-primary;
    display: block;
    width: fit-content;
    margin-inline: auto;
    padding-bottom: .5rem;
    padding-inline: 1.5rem;
  }

  &.heading-sub-special {
    color: $color-primary;
    margin-top: 2rem;
    position: relative;
    display: block;
    width: 100%;
  }


}

.sub-special-decorator {
  width: 15%;
  height: 5px;
  background-color: $color-primary;
  margin-bottom: 2rem;
}