@import '../../assets/styles/variables';
@import 'mdb-react-ui-kit/dist/scss/bootstrap/bootstrap';

.not-found {

  .col-12 {
    display: flex;
    flex-direction: column;
    align-items: center;

    .error-container {
      border: 3px solid $color-secondary-half-opacity;
      border-radius: 6px;
      margin-block: 2rem;
      text-align: center;
      padding-bottom: 1rem;
    }

    p {
      margin: 0;

      &.error-code {
        font-size: 8rem;
        font-weight: 800;
      }

      &.error-status {
        span {
          color: $color-secondary;
        }

        font-weight: 600;
        font-size: 2.5rem;

      }

      &.error-description {
        color: $color-dark-gray;
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }

    }

  }
}

@include media-breakpoint-down(sm) {
  .not-found {
    p {
      &.error-code {
        font-size: 4rem !important;
      }

      &.error-status {
        font-size: 2rem !important;
      }

      &.error-description {
        font-size: 1rem !important;
      }
    }

  }
}