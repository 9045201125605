@import '../../assets/styles/variables';
@import 'mdb-react-ui-kit/dist/scss/bootstrap/bootstrap';

.holder {
  display: flex;
  align-items: center;
  flex-direction: column;

  .banner {
    height: 40vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slogan {
    width: 75%;
    background-color: $color-primary;
    color: $color-white;
    font-size: 2rem;
    text-align: center;
    padding: 1rem;
    border-radius: .5rem;
    margin-top: -2.5rem;
    height: 5rem;
  }
}

@include media-breakpoint-down(md) {
  .holder {
    .banner {
      display: none;
    }

    .slogan {
      margin: 0;
      width: 100%;
      height: fit-content;
      border-radius: 0;
      font-size: 1.143rem;
      text-align: left;
      padding: 2rem;
    }
  }
}