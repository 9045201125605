@import 'mdb-react-ui-kit/dist/scss/bootstrap/bootstrap';
@import '../../assets/styles/variables';

.about-company {

  display: block;
  padding: 0 6rem!important;

}

.about-company-photo {

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 60%;
  }

}

@include media-breakpoint-down(md) {

  .about-company {

    padding: 0 2rem!important;

  }

  .about-company-photo {

    img {
      width: 100%;
    }

  }

}
