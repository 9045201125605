@import 'mdb-react-ui-kit/dist/scss/bootstrap/bootstrap';
@import 'react-toastify/dist/ReactToastify.css';
@import './assets/styles/variables';

html {
  font-size: 16px!important;
}

body {
  margin: 0!important;
  font-family: Roboto, Helvetica, Arial, sans-serif!important;
  -webkit-font-smoothing: antialiased!important;
  -moz-osx-font-smoothing: grayscale!important;
  width: 100%!important;
  background-color: $color-body!important;
}

@include media-breakpoint-down(sm) {
  html {
    font-size: 14px!important;
  }
}
